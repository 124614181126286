import React, { useLayoutEffect } from 'react';
import './App.scss';
import { BrowserRouter, Routes, Route, Link, useLocation } from "react-router-dom";
import { Container, Nav, Navbar } from "react-bootstrap";

import HomePage from './pages/home';
import Footer from './sections/footer';

function App() {
  const ScrollToTopWrapper = ({ children }: any) => {
    const location = useLocation();
    useLayoutEffect(() => {
      document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);
    return children
  }

  return (
    <BrowserRouter>
      <ScrollToTopWrapper>
        <Navbar collapseOnSelect id="mainNav" className="site-header sticky-top text-white" expand="md" bg="light" variant="dark">
          <Container>
            {/* <Navbar.Brand id="branding" href="/"><img className="my-auto" src={require('./images/Ledbury.jpg')} alt="Ledbury Together Logo" /> */}
            {/* </Navbar.Brand> */}
            <h1 style={{marginRight: 20, marginTop: 15}}>Ledbury Together</h1>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto mb-4 mb-xl-0">
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        
        <Routes>
          <Route path="/" element={<HomePage />} />   
        </Routes>
        <Footer />
      </ScrollToTopWrapper>
    </BrowserRouter>
  );
}

export default App;
