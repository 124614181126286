import * as React from "react"
import { Facebook, Linkedin } from "react-bootstrap-icons"

export default function Footer() {
  return (
    <footer className="pb-2 pt-6 bg-tertiary text-white text-center">
      <p className="text-center text-light mt-4">Website designed by <a className="text-white" href="https://www.webhq.uk" target="_blank" rel="noopener noreferrer">WebHQ</a></p>
    </footer>
  )
}
