import * as React from "react"

import { Col, Container, Row } from "react-bootstrap";
import { useEffect } from "react";

import HeroImage from '../images/LedburyTown.jpg';
import Logo from '../images/LTOG.png';

import Modal from 'react-bootstrap/Modal';

function Home() {
  const [imageOpen, setImageOpen] = React.useState<boolean>(false);
  const handleClose = () => setImageOpen(false);
  const handleShow = () => setImageOpen(true);

  useEffect(() => {
    document.title = 'Ledbury Together';
  }, []);

  return (
    <main>
      {/* Hero */}
      <div
        className='text-center hero-container' id='home'
        style={{
          backgroundImage: `url(${HeroImage})`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
      >
        <div className='mask py-8 d-flex justify-content-center align-items-center'>
          <div className='d-flex justify-content-center align-items-center h-100'>
            <div className='text-black px-4 px-md-8 px-lg-10'>
              <img src={Logo} alt="Ledbury Together" className="mb-4" />
              <h1 className='mb-5'>Ledbury Together Ltd</h1>
            </div>
          </div>
        </div>
      </div>

      <Container className="py-8">
        <div className="row featurette align-items-center py-6 py-sm-8">
          <div className="col">

            <hr />
            <h2>Who we are:</h2>
            <p>Our consulting services and marketing business is governed by a team of managers who are bringing significant commercial experience, creative ideas and best practices to Ledbury, and Herefordshire. Our aim is to help our whole community collaborate and ensure positive changes can be implemented faster. </p>

            <h2>Our Mission Statement:</h2>
            <p>To enrich and empower our local Ledbury area and surrounding Herefordshire community through responsible business practices and active community engagement.</p>

            <h2>Our Values and Community Commitments:</h2>
            <p>We prioritize the well-being of our community above all else. We uphold the highest ethical standards in our dealings. We will support all local causes, businesses, traders and charities, and youth development, and will aim to help stimulate Ledbury and Herefordshire tourism development.</p>

            <h2>Governance and Accountability:</h2>
            <p>The company is governed by a small board of directors with commercial, marketing strategy, charity and arts and culture experience, all responsible for upholding our mission and values.</p>

            <h2>Operations:</h2>
            <p>Community representatives will be invited to serve on the sector advisory boards board to ensure community interests are fully considered, and suggestions are passed to the Board. We will provide regular reports on our community impact to all stakeholders, community groups, the local council and Herefordshire council</p>
          </div>
          {/* <div className="col-md-5">
            <img onClick={handleShow} className="img-fluid mw-md-150 mw-lg-130" src={require('../images/Ledbury.jpg')} alt="Ledbury" />
          </div> */}
        </div>

        <Modal show={imageOpen} onHide={handleClose} size='lg'>
          <Modal.Header closeButton>
            <Modal.Title>Ledbury</Modal.Title>
          </Modal.Header>
          <img onClick={handleShow} className="img-fluid" src={require('../images/Ledbury.jpg')} alt="Image of Ledbury town" />
          <Modal.Footer>
            <a className="btn btn-primary text-white" onClick={handleClose}>
              Close
            </a>
          </Modal.Footer>
        </Modal>

        <div className="row featurette align-items-center py-6 py-sm-8">
          <div className="col-md-6 order-md-2">
            <hr />
            <h2>Peter McCann</h2>
            <p>Peter is a creative business founder and builder with years of commercial experience in multi-nationals across Europe and the USA. He has deep experience of the Information Services and IT markets and working within the Insurance and Financial Services market, founded and grew a DataWarehousing business to a £5m turnover with 60 staff. Now as a business and team coach, and a accredited Life Orientations behavioural coach, he has led workshops for groups pf small business entrepreneurs for Worcestershire Council, operated as Growth Coach for Oxford Innovations, and also coached charity chief executives and trustees. Now semi-retired, Peter is helping by collaborating with many Ledbury and Herefordshire initiatives to help them achieve their objectives faster.</p>
            <p>If you would like to bring your skills and experience and volunteer on our Advisory Board  in any of the sectors such as IT and Database Design, Education, Health and Wellbeing, Youth, Tourism, Retail, Employment, Careers and Business Development, or Fund Raising, then please contact Peter McCann at <a href="mailto:petermccann@mccanncoaching.com">petermccann@mccanncoaching.com</a> or calling or texting to <a href="tel:+447768382334">07768 382 334</a></p>
          </div>
          <div className="col-md-4 order-md-1">
            <img className="w-50" src={require('../images/PeterMcCann.png')} alt="Peter McCann" />
          </div>
        </div>

        <div className="row featurette align-items-center py-6 py-sm-8">
          <div className="col-md-6 order-md-2">
            <hr />
            <h2>Nigel Higgs</h2>
            <p>Change Management Coach – Helping businesses identify, define, and integrate change into their organisations painlessly and with panache for over 26 years.</p>
            <p>Specialises in transferring change and project skills into businesses to build confidence and self-sufficiency</p>
          </div>
          <div className="col-md-4 order-md-1">
            <img className="w-50" src={require('../images/NigelHiggs.jpg')} alt="Nigel Higgs" />
          </div>
        </div>

        <div className="row featurette align-items-center py-6 py-sm-8">
          <div className="col-md-6 order-md-2">
            <hr />
            <h2>Stephen Furlonger</h2>
            <p>Stephen is an expert in branding &amp; marketing strategy and an accomplished business leader &amp; developer. With 30 years’ experience in Global advertising and branding agencies he has worked on some of the biggest International household names as well as building stand out brand positioning for new, small businesses and new entrants to market.</p>
            <p>Before joining Ledbury Together Ltd, Stephen built a network of brand consultancies for a Global pre-media company. He helped grow this from start up to £30m turnover in three years with a client list including the World’s largest and leading FMCG companies, grocery retailers and B2B manufacturers &amp; service providers.</p>
            <p>Stephen loves nothing more than getting under the skin of a brand or communications challenge with a focus on leveraging competitive advantage, driving measurable sales and embedding brand thinking to improve corporate culture.</p>
          </div>
          <div className="col-md-4 order-md-1">
            <img className="w-50" src={require('../images/StephenFurlonger.jpg')} alt="Stephen Furlonger" />
          </div>
        </div>

        <div className="row featurette align-items-center py-6 py-sm-8">
          <div className="col-md-6 order-md-2">
            <hr />
            <h2>Don McAll</h2>
            <p>Don has extensive commercial experience, having directed international and national business divisions predominantly in the government and IT industry sectors.  He has also provided consultancy support to large UK and European companies in partnership with Gartner and other global brands.  Having semi-retired in 2021, Don is now the General Manager of Ledbury Rugby Club.</p>
          </div>
          <div className="col-md-4 order-md-1">
            <img className="w-50" src={require('../images/DonMcAll.jpg')} alt="Don McAll" />
          </div>
        </div>

        {/* Call to action */}
      </Container>
      {/* <div
        className='text-center hero-container'
        style={{
          backgroundImage: `url(${CTAImage})`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
      >
        <div className='mask py-8 d-flex justify-content-center align-items-center' style={{
          backgroundColor: 'rgba(0, 0, 0, 0.7)',
          minHeight: '600px',
          margin: 0,
        }}>
          <Row className='d-flex align-items-center h-100'>
            <Col className="d-none d-md-block">
              <img className="img-fluid mw-md-150 mw-lg-130" src={require('../images/software/MonitorView.png')} alt="Clinical waste bag" />
            </Col>
            <Col className='text-white pe-4'>
            </Col>
          </Row>
        </div>
      </div> */}

    </main>
  );
}

export default Home;